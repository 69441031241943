$pr1: #F8FEFF
$pr2: #F9F7FB
$pr3: #F4F6F7
$pr4: #F8E7EF
$pr5: #FEF6F0
$body: #fff
$theme: #0089B6
$dark: #363636
$white: #fff
$light: #F5F5F5
$medium: #505050

$button: $theme
$intro: $dark

$blue: #40BAFF
$violet: #8E4A95
$darkblue: #3f436c
$pink: #EA4E98
$marine: #00AACB
$footer: #44727B
$grey: #cdcdcd

$basic: sans-serif
$inter: $basic

=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
